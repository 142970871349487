<template>
  <div style="width: 98%">
    <h2 style="color: #1a73e8;">制药专业术语翻译助手</h2>
    <div class="container">
      <div class="box input-area">
        <h2>输入区域</h2>
        <form @submit.prevent="submitForm" :rules="rules">
          <select v-model="prompt.language" @change="toggleInputFields">
            <option value="en">英文到中文</option>
            <option value="zh">中文到英文</option>
            <option value="translate">长文本中英互译</option>
          </select>
          <textarea v-model="prompt.word" rows="8" prop="word" placeholder="需要解释或翻译的词语/句子填写到这里，如果翻译结果感觉不行，可以再点击翻译按钮一次，会出现可能不一样的结果"></textarea>
          <input v-if="prompt.language !== 'translate'" v-model="prompt.context" type="text" placeholder="在这里输入这个被翻译的词语/句子是在什么剂型或者业务模块下使用的">
          <input v-if="prompt.language !== 'translate'" v-model="prompt.scenario" type="text" placeholder="在这里输入这个被翻译的词语/句子在什么具体业务场景中使用的">
          <input v-if="prompt.language === 'zh'" v-model="prompt.explanationWord" type="text" placeholder="在这里输入这个中文词语/句子的含义.注意！这里是必须输入的。">
        </form>
      </div>
      <div class="submit-button" v-loading="loading">
        <form @submit.prevent="submitForm">
          <button type="submit" id="submitButton" :style="{ backgroundColor: submitButtonColor }">
            <div id="spinner" v-show="showSpinner"></div>
            翻译
          </button>
        </form>
      </div>
      <div class="box output-area" style="background-color: #ddd;">
        <div class="output-header">
          <h2>解释与翻译</h2>
          <button id="copyButton" :disabled="copyButtonDisabled" @click="copyTranslation">复制</button>
        </div>
        <textarea readonly id="explanationArea" rows="18" placeholder="这里会出现解释结果，请等候">{{ explanation }}</textarea>
      </div>
    </div>
  </div>
</template>

<script>
  import { translate } from "@/api/order";
  export default {
    data() {
      return {
        prompt:{
          language: "en",
          word: null,
          context: null,
          scenario: null,
          explanationWord: ""
        },
        explanation: '',
        submitButtonColor: '#1a73e8',
        showSpinner: false,
        copyButtonDisabled: true,
        loading:false,
        rules: {
          word: [
            { required: true, message: "词语或者句子不能为空", trigger: "blur" }
          ]
        }
      }
    },
    methods: {
      toggleInputFields() {
        if (this.prompt.language === 'translate') {
          this.prompt.context = '';
          this.prompt.scenario = '';
          this.prompt.explanationWord = '';
        }
      },
      submitForm() {
        if (this.prompt.word == null) {
          alert("词语或句子不能为空");
          return;
        }
        this.loading = true;
        translate(this.prompt).then((response) => {
          this.explanation = response.MobileBody.result;
          console.log(response);
          this.loading = false;
          this.copyButtonDisabled = false;
        });
      },
      copyTranslation() {
        // 复制解释结果到剪贴板
        const explanationArea = document.getElementById('explanationArea');
        explanationArea.select();
        document.execCommand('copy');
        alert('翻译结果已复制');
      }
    }
  }
</script>

<style scoped>
  h1, h2 {
    text-align: center;
    margin-top: 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
  }
  .box {
    width: 90%;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    margin-bottom: 20px;
  }
  .input-area,
  .output-area {
    height: 450px;
  }
  .output-area .box {
    background-color: #ddd;
  }
  .output-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;
    max-width: 130px;
  }
  input, textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  #explanationArea {
    flex-grow: 1;
  }
  .copy-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .submit-button {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  #submitButton {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 15px;
    position: relative;
    background-color: #1a73e8;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  #submitButton:hover {
    background-color: #0052cc;
  }
  #submitButton:active {
    background-color: #0052cc;
  }
  #spinner {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: absolute;
    display: none;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  @media (min-width: 600px) {
    .container {
      flex-direction: row;
      justify-content: space-between;
    }
    .box {
      width: 45%;
    }
  }
  @media (max-width: 599px) {
    .container {
      flex-direction: column;
    }
    .box {
      width: 100%;
      box-sizing: border-box;
    }
  }
</style>
