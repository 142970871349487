<template>
  <div style="width: 100%">
    <el-card class="title-card">
      <div slot="header" class="clearfix">
        <el-avatar class="title-image" :src="user.headImg"></el-avatar>
        <span class="name-text">{{ user.nickTame }}</span>
        <el-button
          class="exit"
          icon="el-icon-switch-button"
          @click="exitClick"
          type="primary"
          plain
          >退出</el-button
        >
      </div>
      <div class="information">
        <el-avatar class="content-image" :src="user.headImg"></el-avatar>
        <div class="infor-text">
          <p>
            <span class="count-text">您好</span>
            <span class="count-text" v-if="user.nickName !== null && user.nickName !== ''">,{{user.nickName}}</span>
            <span class="count-text" v-if="user.mobileNo !== null && user.mobileNo !== ''">,{{ user.mobileNo }}</span>
            <el-button v-if="(user.mobileNo === null || user.mobileNo === '') && user.type ==='weixin'" class="button-in" @click="bindMobile" circle>绑定手机号</el-button>
          </p>
          <p>
            <span class="count-vip" v-if="user.vipType === 0"> 普通用户</span>
            <span class="count-vip" v-if="user.vipType === 1"> 月度会员</span>
            <span class="count-vip" v-if="user.vipType === 2"> 年度会员</span>
            <span class="count-text" v-if="user.vipType !== 0"> ，会员有效期：{{ user.vipExpirationTime }}</span>
          </p>
        </div>
        <el-row class="button-invite" style="text-align: center;">
          <el-col>
            <el-button class="button-in" @click="copyLink">复制邀请链接</el-button>
          </el-col>
          <span class="button-font-invite">(邀请使用微信登录成功后有奖)</span>
        </el-row>

<!--          <el-row class="button-content">-->
<!--            <el-col><el-button class="button-in" icon="el-icon-user" circle></el-button></el-col>-->
<!--            <span class="button-font-in">我的账户</span>-->
<!--          </el-row>-->
<!--          <el-row class="button-content">-->
<!--            <el-col-->
<!--              ><el-button icon="el-icon-message" circle></el-button-->
<!--            >-->
<!--            </el-col>-->
<!--            <span class="button-font">帮助中心</span>-->
<!--          </el-row>-->
<!--          <el-row class="button-content">-->
<!--            <el-col -->
<!--              ><el-button icon="el-icon-document" circle></el-button-->
<!--            ></el-col>-->
<!--            <span class="button-font">意见反馈</span>-->
<!--          </el-row>-->
<!--          <el-row class="button-content">-->
<!--            <el-col-->
<!--              ><el-button icon="el-icon-setting" circle></el-button-->
<!--            ></el-col>-->
<!--            <span class="button-font">账户设置</span>-->
<!--          </el-row>-->
      </div>
      <div>
        <el-dialog
                :visible.sync="dialogVisibleBind"
                title="绑定手机号"
                width="40%"
                style="width: 100%"
        >
            <el-form
                    ref="bindForm"
                    :model="bindForm"
                    :rules="bindRules"
                    label-width="80px"
            >
              <el-form-item label="手机号" prop="mobileNo">
                <el-input v-model="bindForm.mobileNo"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="verificationCode">
                <div class="code-container">
                  <el-input v-model="bindForm.verificationCode"></el-input>
                  <div class="code-btn-container">
                    <el-button
                            v-if="!countdown"
                            @click.prevent="sendCode"
                            class="code"
                    >发送验证码</el-button>
                    <span v-else class="countdown">{{ countdown }}s</span>
                  </div>
                </div>
              </el-form-item>
              <div class="btn-container">
                <el-button @click="bindMobileCancel">取 消</el-button>
                <el-button type="primary" @click.prevent="submitForm">绑定</el-button>
              </div>
            </el-form>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogVisible"
          title="充值"
          width="30%"
          style="width: 100%"
        >
          <div class="amount-buttons">
            <el-button
              v-for="amount in amounts"
              :key="amount"
              :type="selectedAmount === amount ? 'primary' : ''"
              @click="selectAmount(amount)"
              >{{ amount }}元</el-button
            >
          </div>
          <div class="custom-amount">
            <el-input
              style="margin-left: 0px; width: 98%"
              type="number"
              :min="10"
              v-model.number="customAmount"
              placeholder="其他金额，请取整数"
            ></el-input>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="confirmAmount">去充值</el-button>
          </div>
        </el-dialog>
        <el-dialog
                :visible.sync="dialogVisibleVip"
                title="购买会员"
                width="30%"
                style="width: 100%"
        >
          <div class="amount-buttons">
            <el-button key="month"
                    :type="selectedVip === 1 ? 'primary' : ''"
                    @click="selectVip(1)"
            >月会员(￥20)</el-button>
            <el-button key="year"
                       :type="selectedVip === 2 ? 'primary' : ''"
                       @click="selectVip(2)"
            >年会员(￥200)</el-button>
<!--            <el-button-->
<!--                    v-for="amount in vipItems"-->
<!--                    :key="amount"-->
<!--                    :type="selectedVip === amount ? 'primary' : ''"-->
<!--                    @click="selectVip(amount)"-->
<!--            >{{ amount }}</el-button-->

          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancelVip">取 消</el-button>
            <el-button type="primary" @click="confirmAmountVip">购买</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="微信扫码支付"
          :visible.sync="QrDialogVisible"
          width="400px"
        >
          <div style="text-align: center">
            <el-row class="button-invite" style="text-align: center;">
              <el-col>
                <img :src="qrcodeUrl" alt="二维码" />
              </el-col>
              <span class="button-font-invite">用微信扫码支付{{ selectedAmount }}元</span>
            </el-row>
          </div>
        </el-dialog>
      </div>
      <!-- <div class="content">
        <p>
          openaliKey:<el-input
            v-model="input"
            placeholder="openai账号。回车或失去焦点保存"
            autofocus="true"
            @change="handleInputChange"
          ></el-input>
        </p>
      </div> -->
    </el-card>

    <el-card class="count-card">
      <div class="content">
        <p>
          <span class="text">余额:</span
          ><span class="text">{{ user.money }}</span>
          <el-button plain class="money" @click="showDialogVip">
            <span class="button-text">购买会员</span>
          </el-button>
<!--          <el-button plain class="money" @click="showDialog">-->
<!--            <span class="button-text">充值</span>-->
<!--          </el-button>-->
        </p>
      </div>
    </el-card>

    <el-tabs v-model="activeName" class="box-card">
      <el-tab-pane label="账单" name="first" tab-position="top">
        <el-card>
          <el-table :data="countData">
            <el-table-column prop="createTime" label="时间" style="width:50%">
            </el-table-column>
            <el-table-column label="金额" style="width:50%">
              <template slot-scope="scope">
                ￥{{ scope.row.money }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="充值" name="second">
        <el-card>
          <el-table :data="moneyData">
            <el-table-column prop="orderId" label="订单号" style="width:20%">
            </el-table-column>
            <el-table-column prop="createTime" label="下单时间" style="width:20%">
            </el-table-column>
            <el-table-column label="金额" style="width:20%">
              <template slot-scope="scope">
                ￥{{ scope.row.realMoney }}
              </template>
            </el-table-column>
            <el-table-column label="状态" style="width:20%">
              <template slot-scope="scope">
                {{ getStatusDesc(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column prop="payTime" label="支付时间" width="200">
            </el-table-column>
          </el-table>
        </el-card>
      </el-tab-pane>
<!--      <el-pagination-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page.sync="currentPage3"-->
<!--        :page-size="100"-->
<!--        layout="prev, pager, next, jumper"-->
<!--        :total="1000"-->
<!--      >-->
<!--      </el-pagination>-->
    </el-tabs>
  </div>
</template>

<script>
import { loginOut, userInfo ,getMobileCode,bindMobile} from "@/api/index";
import { wxPayPre, queryOrder, getOrderList,getBalanceList } from "@/api/order";
import QRCode from "qrcode";
import { VTooltip } from 'v-tooltip';
import ClipboardJS from 'clipboard';
import {Message} from 'element-ui'


export default {
  //   name: "Home",
  directives: {
    'tooltip': VTooltip
  },
  data() {
    return {
      paymentInterval: null, // 轮询定时器
      timeoutInterval: null, // 超时定时器
      dialogVisible: false,
      dialogVisibleVip: false,
      QrDialogVisible: false,
      dialogVisibleBind: false,
      qrcodeUrl: null,
      selectedAmount: null,
      selectedVip:null,
      vipItems:['月会员(￥38)','年会员(￥200)'],
      amounts: [10, 20, 50, 100],
      customAmount: null,
      activeName: "first",
      input: "",
      urls: [
        "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
      ],
      user: {
        money: null,
        mobileNo: null,
        headImg: null,
        nickName: null,
        vipType:null,
        vipExpirationTime:null,
        invitationCode:null,
        type:null
      },
      form: {
        money: undefined,
        orderId: undefined,
        rechargeType:undefined
      },
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      queryBalanceParams: {
        page: 1,
        pageSize: 10,
        type: 2
      },
      countData: [],
      moneyData: [],
      bindForm: {
        mobileNo: undefined,
        verificationCode: "",
      },
      bindRules: {
        mobileNo: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      countdown: 0
    };
  },
  created() {
    this.init();
  },
  methods: {
    submitForm() {
      this.$refs.bindForm.validate((valid) => {
        if (valid) {
          bindMobile(this.bindForm).then((response) => {
            Message({message: "绑定成功", type: 'succcess', duration: 2 * 1000});
            this.$router.go(0);
          });
        } else {
          return false;
        }
      });
    },
    sendCode() {
      if (this.bindForm.mobileNo !== undefined) {
        getMobileCode(this.bindForm).then((response) => {
          console.log(response.data);
        });
        // 发送短信验证码逻辑
        // 倒计时开始
        this.countdown = 60;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    init() {
      userInfo().then((response) => {
        this.user = response.MobileBody;
        console.log(response.MobileBody);
        if (this.user.headImg == null) {
          this.user.headImg =
            "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        }
      });
      getOrderList(this.queryParams).then((response) => {
        this.moneyData = response.MobileBody.orderList;
      });
      getBalanceList(this.queryBalanceParams).then((response) => {
        this.countData = response.MobileBody.balanceList;
      });
    },
    copyLink() {
      const host = window.location.host + "/Login?invitationCode="+this.user.invitationCode;
      const clipboard = new ClipboardJS('.button-in', {
        text: () => host
      });

      clipboard.on('success', () => {
        this.$message.success('链接已复制');
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        this.$message.error('复制链接失败');
        clipboard.destroy();
      });

      clipboard.onClick(event);
    },
    generateQRCode(url) {
      QRCode.toDataURL(url)
        .then((qrcodeUrl) => {
          this.qrcodeUrl = qrcodeUrl;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    startPaymentPolling() {
      this.paymentInterval = setInterval(() => {
        this.checkPaymentStatus();
      }, 3000); // 每3秒轮询一次支付状态，根据实际情况调整间隔时间
      const timeoutDuration = 60000; // 设置超时时间为60秒，根据实际情况调整
      this.timeoutInterval = setTimeout(() => {
        clearInterval(this.paymentInterval); // 停止轮询
        // 处理超时逻辑，例如显示超时提示信息
      }, timeoutDuration);
    },

    checkPaymentStatus() {
      queryOrder(this.form)
        .then((response) => {
          const status = response.MobileBody.status;
          if (status === 1) {
            this.$message.success("支付成功");
            this.handlePaymentSuccess();
          } else if (status === 2) {
            this.$message.error("已取消支付");
            this.handlePaymentSuccess();
          } else {
            // 支付失败或其他状态，根据实际情况处理
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handlePaymentSuccess() {
      clearTimeout(this.timeoutInterval); // 清除超时定时器
      clearInterval(this.paymentInterval); // 停止轮询
      this.init();
      this.QrDialogVisible = false; // 关闭弹窗
      this.form.orderId = null;
      this.selectedAmount = null;
      this.customAmount = null;
    },
    handleTimeout() {
      clearInterval(this.paymentInterval); // 停止轮询
      // 处理超时逻辑，例如显示超时提示信息
    },
    //退出
    exitClick() {
      loginOut().then((response) => {
        this.$router.push({ path: "/Login" });
      });
    },
    bindMobile() {
      this.dialogVisibleBind = true;
    },
    bindMobileCancel() {
      this.dialogVisibleBind = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    showDialogVip() {
      this.dialogVisibleVip = true;
    },
    cancelVip() {
      this.dialogVisibleVip = false;
    },
    cancel() {
      this.dialogVisible = false;
      this.selectedAmount = null;
      this.customAmount = null;
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.customAmount = amount;
    },
    selectVip(type){
      this.selectedVip = type;
      if (type ===1) {
        this.selectedAmount = 20;
      } else {
        this.selectedAmount = 200;
      }
      this.form.rechargeType = type;
    },
    confirmAmountVip() {
      if (this.selectedAmount != null) {
        this.form.money = this.selectedAmount * 100;
        wxPayPre(this.form).then((response) => {
          this.generateQRCode(response.MobileBody.codeUrl);
          this.dialogVisibleVip = false;
          this.QrDialogVisible = true;
          this.form.orderId = response.MobileBody.orderId;
          this.startPaymentPolling();
        });
      } else {
        this.$message.error("请选择会员类型");
      }
    },
    confirmAmount() {
      if (this.selectedAmount != null || this.customAmount != null) {
        this.form.money = this.customAmount * 100;
        wxPayPre(this.form).then((response) => {
          this.generateQRCode(response.MobileBody.codeUrl);
          this.dialogVisible = false;
          this.QrDialogVisible = true;
          this.form.orderId = response.MobileBody.orderId;
          this.startPaymentPolling();
        });
      } else {
        this.$message.error("请选择一个金额或输入自定义金额");
      }
    },
    getStatusDesc(status) {
      if (status === 0) {
        return "未付款";
      } else if (status === 1) {
        return "已付款";
      } else if (status === 2) {
        return "已取消";
      } else if (status === 3) {
        return "已完成";
      } else if (status === 4) {
        return "已退款";
      } else if (status === 5) {
        return "已删除";
      } else {
        return "";
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutInterval); // 清除超时定时器
    clearInterval(this.paymentInterval); // 组件销毁时停止轮询
  },
};
</script>

<style scoped>
.title-card {
  width: 98%;
  background-color: #fafafa;
}

.box-card {
  margin: 20px;
  width: 97%;
}

.exit {
  padding: 3px 0;
  width: 80px;
  height: 30px;
  border-radius: 5px;
}

.clearfix {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title-image {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
}

.name-text {
  font-size: 14px;
  margin-right: 30px;
}

.count-card {
  margin: 20px;
  height: 60px;
  line-height: 60px;
  padding-bottom: 30px;
  border-radius: 10px;
  background-image: linear-gradient(to right, #ff7a45, #febe67);
}

.el-input {
  width: 300px;
  margin-left: 20px;
}

.content-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.content {
  height: 40px;
  margin: 5px;
  line-height: 40px;
}

.button-content{
  /* width: 50px; */
  margin-right: 0;

}

.button-font {
  font-size: 10px;
}

.button-font {
  font-size: 10px;
}

.button-in {
  color: #1f75e8;
}

.button-font-in {
  font-size: 10px;
  color: #1f75e8;
}

.button-font-invite {
  font-size: 10px;
  color: red;
}

.information {
  height: 40px;
  margin: 5px;
  display: flex;
  padding: 15px;
}

.infor-text {
  width: 95%;
  margin: -10px 0 0 5px;
  font-size: 12px;
}

.count-text {
  margin-bottom: -10px;
}

.count-vip {
  margin-bottom: -10px;
  margin-top: -10px;
  background-image: linear-gradient(to right, #ff7a45, #febe67);
  font-size: 16px;
}

.count-text {
  margin-top: -10px;
}

.text {
  margin-left: 15px;
  color: white;
}

.money {
  /* margin-left: 50px; */
  width: 80px;
  height: 30px;
  padding-top: 1px;
  line-height: 30px;
  float: right;
  background-color: white;
}

.button-text {
  color: #ff7a45;
}

.amount-buttons {
  margin-bottom: 10px;
}

.custom-amount {
  margin-bottom: 10px;
}

.dialog-footer {
  text-align: right;
}

.el-pagination {
  text-align: center;
}

.btn-container {
  margin-top: 20px;
  text-align: center;
}

.code-container {
  display: flex;
  align-items: center;
}

.countdown {
  margin-left: 10px;
  color: #999;
}
.code {
  margin: 0 25px 0 15px;
  padding: 12px;
}
</style>
