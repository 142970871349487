<template>
  <div id="login-page">
    <div class="login-container">
      <img class="image" src="https://gxpresource.oss-cn-shenzhen.aliyuncs.com/login_img.png" />
      <div class="container">
        <img class="login_image" src="/pic/login_logo@2x.png" />
        <div class="title">登录</div>
        <el-form
                ref="loginForm"
                :model="loginForm"
                :rules="loginRules"
                label-width="80px"
        >
          <el-form-item label="手机号" prop="mobileNo">
            <el-input v-model="loginForm.mobileNo"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="verificationCode">
            <div class="code-container">
              <el-input v-model="loginForm.verificationCode"></el-input>
              <div class="code-btn-container">
                <el-button
                        v-if="!countdown"
                        @click.prevent="sendCode"
                        class="code"
                >发送验证码</el-button>
                <span v-else class="countdown">{{ countdown }}s</span>
              </div>
            </div>
          </el-form-item>
          <div class="btn-container">
            <el-button type="primary" @click.prevent="submitForm">登录</el-button>
            <el-button @click.prevent="wechatLogin">微信登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="text">琼ICP备19003923号</div>
  </div>
</template>

<script>
  import { getMobileCode, login, wxLoginCode } from "@/api/index";

  export default {
    data() {
      return {
        loginForm: {
          mobileNo: undefined,
          verificationCode: "",
        },
        loginRules: {
          mobileNo: [
            { required: true, message: "请输入手机号", trigger: "blur" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "手机号格式不正确",
              trigger: "blur",
            },
          ],
          verificationCode: [
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
        },
        countdown: 0,
        inviteForm: {
          invitationCode: "",
        }
      };
    },
    created() {
      this.inviteForm.invitationCode = this.getParams("invitationCode");
      if (this.inviteForm.invitationCode === null) {
        this.inviteForm.invitationCode = "";
      }
    },
    methods: {
      sendCode() {
        if (this.loginForm.mobileNo !== undefined) {
          getMobileCode(this.loginForm).then((response) => {
            console.log(response.data);
          });
          // 发送短信验证码逻辑
          // 倒计时开始
          this.countdown = 60;
          const timer = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(timer);
            }
          }, 1000);
        }
      },
      submitForm() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            // let token =
            //   "gxpcodeeyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJneHBjb2RlIiwib3Blbl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwidXNlcl9pZCI6ImE3MDIwMjFjOTBjYjQ4NTZiOWEzZGRlOGJmYTQwNDM0IiwiaWF0IjoxNjg5NDc0MDYyLCJleHAiOjE2OTAwNzg4NjJ9.HZ8owJiGQbmmAti9k-3m15bitrOIdO8K83WpCZZTc68";
            // localStorage.setItem("token", token);
            // this.$router.push({ path: "/" });
            login(this.loginForm).then((response) => {
              let token = response.MobileBody.token;
              localStorage.setItem("token", token);
              this.$router.push({ path: "/" });
            });
          } else {
            return false;
          }
        });
      },
      wechatLogin() {
        // alert(this.inviteForm.invitationCode);
        wxLoginCode(this.inviteForm).then((response) => {
          let loginUrl = response.MobileBody.codeUrl;
          window.location.href = loginUrl;
        });
      },
      getParams(str) {
        const url = window.location.href.split("?")[1];
        if (url !== undefined) {
          var attrs = url.split("&");
          for(var i in attrs) {
            var value = attrs[i].split("=");
            if (str === value[0]) {
              return value[1];
            }
          }
        }
        return "";
      }
    },
  };
</script>

<style scoped>
  #login-page {
    width: 100%;
    height: 100vh;
    background-image: url("https://gxpresource.oss-cn-shenzhen.aliyuncs.com/img_bg.png");
    background-size: cover;
    background-position: center;
  }

  .image {
    height: 90%;
    margin: 20px;
    flex: 1;
  }

  .container {
    float: left;
    margin: 20px;
    flex: 1;
  }

  .login_image {
    height: 50px;
    width: 126px;
    margin: 0 25%;
  }

  .title {
    font-family: HarmonyOS_Sans_SC_Bold;
    font-size: 20px;
    /* font-weight: bold; */
    line-height: 28px;
    letter-spacing: 0px;
    color: #000000;
    margin: 10px;
  }

  .el-form-item {
    margin: 30px 0;
  }

  .el-input {
    width: 90%;
  }

  .el-button {
    width: 100px;
  }

  .code {
    margin: 0 25px 0 15px;
    padding: 12px;
  }

  .login-container {
    width: 60%;
    margin: 75px 20%;
    float: left;
    background-color: white;
    height: 400px;
    display: flex;
  }

  .btn-container {
    margin-top: 20px;
    text-align: center;
  }

  .code-container {
    display: flex;
    align-items: center;
  }

  .countdown {
    margin-left: 10px;
    color: #999;
  }

  .wechat-login-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    font-size: 14px;
    width: 100%;
    height: 20px;
    text-align: center;
    bottom: 20px;
    position: relative;
  }

  @media (max-width: 768px) {
    /* 在屏幕宽度小于等于768px时应用的样式 */
    #login-page {
      background-size: contain;
    }
    .login-container {
      width: 100%;
      margin: 0;
      height: auto;
      flex-direction: column;
    }
    .image {
      height: 300px;
      margin: 20px 0;
      flex: none;
    }
    .container {
      float: none;
      margin: 20px 0;
      flex: none;
    }
    .title {
      font-size: 16px;
      margin-left: 20px;
    }
    .el-input {
      width: 100%;
    }
    .el-button {
      width: 100%;
      margin: 10px 0;
    }
    .code {
      margin: 0;
    }
    .btn-container {
      margin-top: 10px;
    }
    .text {
      font-size: 12px;
    }
  }
</style>
