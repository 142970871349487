<template>
  <el-container>
    <el-aside width="64px">
     <common-aside></common-aside>
    </el-aside>
    <el-container>
        <router-view/>
    </el-container>
  </el-container>
</template>

<style>

</style>

<script>
import CommonAside from '../components/CommonAside.vue';
export default {
  components: { CommonAside },
  // data() {
  // },
  comments:{
    CommonAside
  }
};
</script>

