<template>
  <div class="container">
    <el-card class="box-card">
      <div class="content">
        <span class="content-text">您好，我们是您身边的制药专业小能手！</span>
      </div>
      <div class="contact-us">
        <el-button type="primary" @click="showContactModal">联系我们</el-button>
        <div class="qr-code-container">
          <el-dialog class="qr-code-dialog" title="联系我们" :visible.sync="contactModalVisible" @close="closeContactModal">
            <img class="qr-code-image" src="https://gxpresource.oss-cn-shenzhen.aliyuncs.com/contact.jpg" alt="二维码图片">
          </el-dialog>
        </div>
      </div>
    </el-card>
    <el-card class="app-card">
      <div slot="header">
        <span>应用中心</span>
      </div>
      <div class="app-content">
        <div class="trade" @click="redirectToPage">
          <img class="trade-image" src="@/assets/img_fanyi@2x.png" />
          <div class="text-container">
            <span class="trade-text-zh">翻译助手</span>
<!--            <span class="trade-text-en">TradeManager Assistant</span>-->
          </div>
        </div>
        <div class="devia"  @click="redirectToTools(1)">
          <img class="devia-image" src="@/assets/img_pianchazhushou@2x.png" />
          <div class="text-container">
            <span class="trade-text-zh">GMP助手</span>
<!--            <span class="trade-text-en">GMP</span>-->
          </div>
        </div>
        <div class="other" @click="redirectToTools(2)">
          <img class="other-image" src="@/assets/thumb3.png" />
          <div class="text-container">
            <span class="trade-text-zh">DI&CSV助手</span>
<!--            <span class="trade-text-en">DI&CSV</span>-->
          </div>
        </div>
        <div class="trade" @click="redirectToTools(3)">
          <img class="trade-image" src="@/assets/thumb2.png" />
          <div class="text-container">
            <span class="trade-text-zh">GSP助手</span>
<!--            <span class="trade-text-en">GSP</span>-->
          </div>
        </div>
        <div class="devia"  @click="redirectToTools(4)">
          <img class="devia-image" src="@/assets/thumb1.png" />
          <div class="text-container">
            <span class="trade-text-zh">无菌保障助手</span>
<!--            <span class="trade-text-en">无菌保障</span>-->
          </div>
        </div>
        <div class="other" @click="redirectToTools(5)">
          <img class="other-image" src="@/assets/img_qita@2x.png" />
          <div class="text-container">
            <span class="trade-text-zh">MAH助手</span>
<!--            <span class="trade-text-en">MAH</span>-->
          </div>
        </div>
        <div class="more">
          <img class="more-image" src="@/assets/todo.png" />
          <div class="text-container">
            <span class="more-text-zh">偏差评估助手</span>
            <!--            <span class="trade-text-en">MAH</span>-->
          </div>
        </div>
        <div class="more">
          <img class="more-image" src="@/assets/todo.png" />
          <div class="text-container">
            <span class="more-text-zh">注册申报助手</span>
            <!--            <span class="trade-text-en">MAH</span>-->
          </div>
        </div>
        <div class="more">
          <img class="more-image" src="@/assets/todo.png" />
          <div class="text-container">
            <span class="more-text-zh">更多专业助手</span>
            <!--            <span class="trade-text-en">MAH</span>-->
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import { loginOut, userInfo } from "@/api/index";
  import { wxPayPre, queryOrder, getOrderList } from "@/api/order";
  export default {
    data() {
      return {
        contactModalVisible: false,
        urls: [
          "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg",
        ],
        user: {
          money: null,
          mobileNo: null,
          headImg: null,
        },
      };
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        // userInfo().then((response) => {
        //   this.user = response.MobileBody;
        //   if (this.user.headImg == null) {
        //     this.user.headImg =
        //       "https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg";
        //   }
        // });
      },
      redirectToPage() {
        this.$router.push('/Application'); // 跳转到目标页面
        this.$router.go(0);
      },
      redirectToTools(obj) {
        this.$router.push({
          path: '/Chat/'+obj,
        });
      },
      showContactModal() {
        this.contactModalVisible = true;
      },
      closeContactModal() {
        this.contactModalVisible = false;
      }
    }
  };
</script>

<style scoped>
  .contact-us {
    display: flex;
    justify-content: flex-end;
  }
  .qr-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px; /* 设置容器的高度 */
  }
  .qr-code-dialog {
    justify-content: center;
    align-items: center;
    width: 100%; /* 设置容器的高度 */
    height: 100%;
  }
  .qr-code-image {
    max-width: 100%;
    max-height: 20%;
  }
  .container {
    width: 98%;
    height: 100vh;
  }

  .box-card {
    width: 100%;
    background-color: #fafafa;
  }

  .content {
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }

  .content-image {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 10px;
  }

  .content-text {
    font-size: 20px;
    margin-top: 15px;
    margin-left: 10px;
    text-align: center;
  }

  .app-card {
    margin: 20px;
  }

  .app-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .trade,
  .devia,
  .more,
  .other {
    flex-basis: calc(33.33% - 20px);
    height: 160px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .trade {
    /* width: 30%; */
    background: rgba(47, 105, 255, 0.1);
  }
  .devia {
    /* width: 30%; */
    background: rgba(41, 197, 238, 0.12);
  }
  .other {
    /* width: 30%; */
    background: rgba(255, 121, 68, 0.1);
  }

  .more {
    /* width: 30%; */
    background: gainsboro;
  }

  .trade-image,
  .devia-image,
  .more-image,
  .other-image {
    width: 75px;
    height: 75px;
    margin-bottom: 10px;
  }

  .trade-text-zh,
  .devia-text-zh,
  .other-text-zh {
    font-size: 24px;
    font-weight: 500;
    color: #316bff;
    margin-bottom: 5px;
  }
  .more-text-zh {
    font-size: 24px;
    font-weight: 500;
    color: gray;
    margin-bottom: 5px;
  }

  .trade-text-en,
  .devia-text-en,
  .other-text-en {
    font-size: 14px;
    color: rgba(49, 106, 255, 0.3);
    margin-bottom: 5px;
  }

  .more-text-en {
    font-size: 14px;
    color: gray;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    .trade,
    .devia,
    .other,
    .more {
      flex-basis: calc(50% - 20px);
    }
  }
</style>
