import http from '../utils/request'

//请求xx数据
export const getData = () =>{
    //返回一个promise对象
    return http.get('url')
}

// 获取验证码
export function getMobileCode(data) {
    return http({
        url: '/user/getMobileCode',
        method: 'post',
        data: data
    })
}

// 登录&注册
export function login(data) {
    return http({
        url: '/user/mobileRegister',
        method: 'post',
        data: data
    })
}

// 绑定手机号
export function bindMobile(data) {
    return http({
        url: '/user/bindMobile',
        method: 'post',
        data: data
    })
}


// 退出
export function loginOut() {
    return http({
        url: '/user/logout',
        method: 'post',
        data: null
    })
}

// 获取用户信息
export function userInfo() {
    return http({
        url: '/user/getUserInfo',
        method: 'post',
        data: null
    })
}

// 微信登录
export function wxLoginCode(query) {
    return http({
        url: '/user/wxLoginCode',
        method: 'get',
        params: query
    })
}

// 聊天
export function doChat(data) {
    return http({
        url: '/assistant/chatConsultation',
        method: 'post',
        data: data
    })
}

// 聊天记录
export function chatHistory(data) {
    return http({
        url: '/assistant/getChatRecordList',
        method: 'post',
        data: data
    })
}