import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import Chat from '../views/Chat'
import Count from '../views/Count'
import Application from '../views/Application'
import Knowledge from '../views/Knowledge'
import Market from '../views/Market'
import Login from '../views/Login'
import LoginWx from "../views/LoginWx";
import Help from "../views/Help";
import Feedback from "../views/Feedback";
import Setting from "../views/Setting";

Vue.use(VueRouter);

/*//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};*/



const routes = [
  {
    path: '/',
    component: Index,
    redirect: '/Market',
    children: [
      {
        path: '/Count',
        component: Count,
        meta: { selectedMenu: 'Count' }
      },
      {
        path: '/Chat/:name',
        name: 'Chat',
        component: Chat,
        meta: { selectedMenu: 'Chat' }
      }
      ,
      {
        path: '/Application',
        component: Application,
        meta: { selectedMenu: 'Application' }
      }
      ,
      {
        path: '/Knowledge',
        component: Knowledge,
        meta: { selectedMenu: 'Knowledge' }
      }
      ,
      {
        path: '/Market',
        component: Market,
        meta: { selectedMenu: 'Market' }
      },
      {
        path: '/Feedback',
        component: Feedback,
        meta: { selectedMenu: 'Feedback' }
      },
      {
        path: '/Setting',
        component: Setting,
        meta: { selectedMenu: 'Setting' }
      },
      {
        path: '/Help',
        component: Help,
        meta: { selectedMenu: 'Help' }
      }
    ]
  },
  {
    path: '/Login',
    component: Login
  },
  {
    path: '/LoginWx',
    component: LoginWx
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const whiteList = ['/Login', '/Market']

// router.beforeEach((to, from, next) => {
//     if (whiteList.indexOf(to.path) !== -1) {
//       // 在免登录白名单，直接进入
//       next()
//     } else {
//       const token = localStorage.getItem('token');
//       // 将token添加到请求头中
//       if (token !== undefined) {
//         next()
//       } else {
//         next(`/Login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
//       }
//     }
// })
//
// router.afterEach(() => {
//   NProgress.done()
// })
export default router
