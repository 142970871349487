<template>
  <el-menu
    :collapse="true"
    mode="horizontal"
    style="height: 100vh; position: fixed; background-color: #31394a"
    :default-active="$router.currentRoute.meta.selectedMenu"
  >
    <el-menu-item-group v-for="item in menuData" :key="item.name" :index="item.name">
      <el-menu-item @click="clickMenu(item)" :index="item.index">
        <i :class="`el-icon-${item.icon}`"><div style="font-size: 12px">{{item.label}}</div></i>
      </el-menu-item>
    </el-menu-item-group>
  </el-menu>
</template>

<style>
</style>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          path: "/Market",
          name: "market",
          label: "市场",
          icon: "setting",
          url: "Market",
          index: "Market",
        },
        // {
        //   path: "/Chat",
        //   name: "chat",
        //   label: "聊天",
        //   icon: "s-home",
        //   url: "Chat",
        //   index: 'Chat',
        // },
        {
          path: "/Application",
          name: "application",
          label: "翻译",
          icon: "menu",
          url: "Application",
          index: 'Application',
        },
        // {
        //   path: "/Knowledge",
        //   name: "knowledge",
        //   label: "知识库",
        //   icon: "setting",
        //   url: "Knowledge",
        //   index: '3',
        // },
        {
          path: "/Count",
          name: "count",
          label: "账户",
          icon: "setting",
          url: "Count",
          index: 'Count',
        },
      ],
    };
  },
  methods:{
    clickMenu(item){
      console.log("i",item)
      this.$router.push(item.path)
    }
  }

};
</script>

